import { css } from '@emotion/core'

export const globalStyles = css`
	html, body, div, span, applet, object, iframe,
	h1, h2, h3, h4, h5, h6, p, blockquote, pre,
	a, abbr, acronym, address, big, cite, code,
	del, dfn, em, img, ins, kbd, q, s, samp,
	small, strike, strong, sub, sup, tt, var,
	b, u, i, center,
	dl, dt, dd, ol, ul, li,
	fieldset, form, label, legend,
	table, caption, tbody, tfoot, thead, tr, th, td,
	article, aside, canvas, details, embed, 
	figure, figcaption, footer, header, hgroup, 
	menu, nav, output, ruby, section, summary,
	time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
	}
	/* HTML5 display-role reset for older browsers */
	article, aside, details, figcaption, figure, 
	footer, header, hgroup, menu, nav, section {
		display: block;
	}
	body {
		line-height: 1;
	}
	html {
		font-family: 'Poppins', sans-serif;
	}
	ol, ul {
		list-style: none;
	}
	blockquote, q {
		quotes: none;
	}
	blockquote:before, blockquote:after,
	q:before, q:after {
		content: '';
		content: none;
	}
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}
	a {
		text-decoration: none;
	}
	/* TABS */
	.react-tabs__tab-list {
		border-bottom: 1px solid #E4EAEE;
		padding: 0;
		display: flex;
		font-size: 14px;
		color: #CCCCCC;
		height: auto;
		display: flex;
	}

	.react-tabs__tab {
		display: block;
		border-top: none;
		border-right: none;
		border-left: none;
		text-align: center;
		bottom: -1px;
		position: relative;
		list-style: none;
		padding: 25px 12px;
		cursor: pointer;
		width: 100%;
		&:not(:has('.react-tabs__tab--disabled')):hover {
			color: #0770CF;
		}
	}
	.react-tabs__tab:focus {
		border: none;
		box-shadow: none;
		border-bottom: 1px solid #0770CF;
		outline: none;
	}
	.react-tabs__tab:focus::after {
		height: 0!important;
	}
	.react-tabs__tab--selected {
		background: #fff;
		color: #0770CF;
		font-weight: bold;
		border-bottom: 1px solid #0770CF;
	}

	.react-tabs__tab--disabled {
		color: GrayText;
		cursor: default;
	}

	.react-tabs__tab:focus:after {
		content: "";
		position: absolute;
		height: 5px;
		left: -4px;
		right: -4px;
		bottom: -5px;
		background: #fff;
	}

	.react-tabs__tab-panel {
		display: none;
	}

	.react-tabs__tab-panel--selected {
		display: block;
	}
`